body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.large-btn {
  display: block;
  width: 100%;
  border: none;
  background-color: #04aa6d;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.sign-in-btn {
  display: block;
  max-width: 220px;
  min-width: 205px;
  border: none;
  border-radius: 3px;
  background-color: #04aa6d;
  color: #fff;
  padding: 10px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  margin: auto;
}

button:disabled {
  background-color: #8dbfa5;
  color: #fff;
  cursor: default;
  opacity: 0.6;
}


.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 16px;
}

.input-container {
  flex: 1;

  font-size: 18px;
}

label {
  display: block;
  text-align: left;
  margin-right: 10px; /* Adds some space between the label and the input */
/*  text-align: left;*/
}

.input-container input {
  font-size: 20px;
}


.App-home-ID {
  display: flex;
  flex-direction: column;
}
